<template>
  <div>
    <div>
      <ul class="navigation-bar">
        <li
          class="navigation-bar__item"
          :class="{
            'navigation-bar__item--disabled': compareDates(item.start_date) || !companyHasEdition(item.id),
            'navigation-bar__item--active': item.id == activeEdition
          }"
          :tooltipMessage="getTooltipMessage(item.start_date, item.id)"
          v-for="item in visibleEditionsList"
          :key="item.name"
        >
          <button @click="setActiveEdition(item.id)">{{ item.name }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['visibleEditionsList', 'companyEditions', 'activeEdition']),
    dateTimeNow() {
      return new Date().toISOString().slice(0, 10);
    },
  },
  async created() {
    await this.$store.dispatch('getEditionsData');
    this.$router.replace({
      query: { edition: this.activeEdition },
    });
  },
  methods: {
    compareDates: function(date) {
      const passedDate = new Date(date).toISOString().slice(0, 10);
      return passedDate > this.dateTimeNow;
    },
    companyHasEdition: function(id) {
      const boughtEditions = this.companyEditions;
      return boughtEditions.includes(id);
    },
    getTooltipMessage: function(startDate, id) {
      if (!this.companyHasEdition(id)) {
        return 'Twoja firma jeszcze nie wykupiła tej edycji';
      }
      if (this.compareDates(startDate)) {
        return `Uzupełnianie tej edycji będzie dostępne od: ${startDate}`;
      }
    },
    setActiveEdition: function (id) {
      this.$store.dispatch("setActiveEdition", id).then((response) => {
        this.$router.push({
          path: '/informacje-organizacyjne',
          query: { edition: id },
        });
      });
    },
  },
};
</script>
<style lang="scss">
.navigation-bar {
  display: inline-flex;
  flex-wrap: wrap;
  list-style-type: none;
  border-bottom: 1px solid $light-gray-color;
  margin-top: 60px;
  @include desktop {
    margin-top: 0;
  }

  &__item {
    font-size: 12px;
    padding: 0 10px 5px 10px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    @include tablet {
      margin-bottom: 0;
      padding: 0 30px 5px 30px;
    }

    &:after {
      content: "";
      height: 2.5px;
      width: 0;
      background: $primary-color;
      position: absolute;
      bottom: -1px;
      left: 50%;
      transition: 0.5s;
    }

    &:hover {
      color: $primary-color;

      &:after {
        width: 100%;
        left: 0;
      }
    }

    &--active {
      color: $primary-color;

      &:after {
        content: "";
        height: 2.5px;
        width: 100%;
        background: $primary-color;
        position: absolute;
        bottom: -1px;
        left: 0;
        transition: 0.5s;
      }
    }

    &--disabled {
      color: $light-gray-color;
      cursor: help;

      button {
        pointer-events: none;
      }

      &:before {
        display: none;
      }

      &:hover {
        color: $light-gray-color;

        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-width: 10px;
          border-style: solid;
          border-color: #fff #fff $primary-color #fff;
          top: 25px;
          left: 55px;
          transition: 0s;
        }

        &:before {
          content: attr(tooltipMessage);
          z-index: 9000;
          bottom: -78px;
          min-width: 230px;
          font-weight: 500;
          font-style: italic;
          position: absolute;
          display: block;
          padding: 10px 20px;
          background: $primary-color;
        }
      }
    }
  }
}
</style>
